<template>
  <div>
    <!--叶子级菜单-->
    <template v-if="item.children === null">
      <el-menu-item :key="item.id" :index="item.path">
        {{ item.nameZh }}
      </el-menu-item>
    </template>
    <!--父级菜单-->
    <el-submenu v-else :index="item.path" style="text-align: left">
      <template slot="title">
        <i :class="item.iconCls"></i>
        {{ item.nameZh }}
      </template>

      <el-menu class="el-menu-vertical-demo-chi" :collapse="true" unique-opened>
        <navigation-item
          v-for="child in item.children"
          :key="child.id"
          :item="child"
        />
      </el-menu>
    </el-submenu>
  </div>
</template>
 
<script>
export default {
  name: "navigationItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.el-menu-vertical-demo-chi {
  width: 100% !important;
}
</style>
